import styled from "styled-components";
import { darken, transparentize } from "polished";

export const Wrapper = styled.div`
  height: 100%;
`;

export const MainContent = styled.div`
  padding: 2rem 2rem 5rem 2rem;
  & p {
    padding-bottom: 1rem;
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: start;
  margin-bottom: 1.5rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${(p) => p.theme.subheader.background};
  padding: 1rem 2rem;
  border-bottom: 1px solid ${(p) => p.theme.subheader.border};

  & h1 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
`;

export const TextFieldWrapper = styled.div`
  margin-bottom: 12px;
`;

export const AdminContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const AdminContentSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 1rem;
`;

export const AdminContentSectionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  h2 {
    margin: 0;
    padding: 0 0 0 1px;
    line-height: 1;
  }
`;

export const ComingSoonNote = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & p {
    margin: 0;
    padding: 0;
  }
  font-size: 0.875rem;
  background-color: ${(p) =>
    transparentize(0.8, p.theme.color.palette.butterYellow)};
  color: ${(p) => p.theme.text.muted};
  border: 1px solid ${(p) => p.theme.border.contrast};
  padding: 2rem;
  max-width: 800px;
  border-radius: 1rem;
`;

export const MaterialColorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  font-size: 15px;

  // 'visibility: hidden' and 'display: none' both prevent tab navigation from
  // focussing on the input element, so we make it invisible instead.
  & input[type="color"] {
    opacity: 0;
    height: 0;
    width: 0;
    border: none;
    color-scheme: ${(p) => p.theme.input.colorScheme};
    pointer-events: none;
  }
`;

export const MaterialColorBox = styled.div.attrs<{ $color: string }>((p) => ({
  // The use of `style` is based on the styled-components console output below:
  // >  Over 200 classes were generated for component MaterialColorBox with the
  // >  id of "MaterialColorBox-sc-g61vsi-15".  Consider using the attrs method,
  // >  together with a style object for frequently changed styles.
  style: {
    borderColor: darken(0.1, p.$color),
    backgroundColor: p.$color,
  },
}))`
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
`;

export const MaterialColorFormFieldContainer = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.input.default.border};
  border-radius: 2px;

  transition: border 0.1s ease;

  &:focus-within {
    border-color: ${(p) => p.theme.input.focused.border};
  }
`;

export const SideBySide = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
`;

export const Checkbox = styled.input`
  accent-color: ${(p) => p.theme.color.palette.electricBlue};

  // Invert the color of the checkbox when it's not checked, so that it has a dark background rather than a white one.
  &:not(:checked) {
    filter: invert(90%);
  }
`;

export const LabelledCheckboxContainer = styled.label`
  display: flex;
  vertical-align: middle;
  gap: 0.5rem;
`;

export const ControlLabel = styled.span`
  font-size: 0.875rem;
  color: ${(p) => p.theme.text.muted};
`;

export const Checkmark = styled.span`
  margin-left: 1rem;
`;

export const ArchiveMessage = styled.div`
  margin-bottom: 1rem;
`;

export const OrgDeniedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

export const OrgDeniedMessage = styled.div`
  max-width: 600px;
  font-size: 1.4rem;
`;

export const ErrorMessage = styled.div`
  color: ${(p) => p.theme.input.invalid.text};
  padding: 2rem 0;
`;
