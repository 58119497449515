import styled from "styled-components";

import { LogoFullSize } from "_/components/logo";
import { Icon as _Icon } from "_/components/icon";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100vw;
  padding: 12px 14px;

  background-color: ${(p) => p.theme.header.background};
`;

export const TitleLogo = styled(LogoFullSize)`
  margin: 0 auto 0 0;
  cursor: pointer;
`;

export const Nav = styled.nav`
  font-size: 0.5em;

  display: flex;
  align-items: center;
  gap: 30px;
`;

export const SelectWrapper = styled.div`
  & .form-field {
    margin-bottom: 0;
  }
  & .react-select__control {
    background-color: transparent;
    cursor: pointer;
  }
  & .react-select__option {
    cursor: pointer;
  }
  & .react-select__control:not(:hover) {
    background-color: transparent;
    color: ${(p) => p.theme.text.muted};
    & .react-select__dropdown-indicator {
      color: ${(p) => p.theme.text.muted};
    }
  }
  & .react-select__control--is-focused {
    color: ${(p) => p.theme.input.default.text};
    & .react-select__dropdown-indicator {
      color: ${(p) => p.theme.input.default.text};
    }
  }
`;

export const UserInfoContainer = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  color: ${(p) => p.theme.text.default};
  margin-right: 8px;
`;

export const UserName = styled.span`
  margin: auto;
  font-size: 14px;
`;

export const DownIcon = styled(_Icon)`
  margin: auto;
  margin-left: -0.6rem;
  font-size: 1.2rem;
`;
