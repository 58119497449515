/**
 * Machines view page component.
 *
 * This component manages the required data, queries, and mutations, and
 * renders the subcomponents for each machines category.
 *
 * @category pages
 * @module machines-view
 */

import React, { ReactElement, ReactNode } from "react";
import { Route } from "_/components/router";
import { t } from "@lingui/macro";

import { MachineDetails } from "_/components/machine-details";
import {
  FullDetachedMachineListing,
  FullMachineListing,
} from "_/components/machine-listing";
import { H5 } from "_/components/typography";

import { routes } from "_/routes";
import { useMachine, useMachines } from "_/data/machines";
import { isValidUuid } from "_/utils";
import { Uuid } from "_/types";

import * as S from "./styled";

const UrlParamError = (props: {
  heading: string;
  description: string;
}): ReactElement => (
  <S.Wrapper>
    <H5>{props.heading}</H5>
    <p>{props.description}</p>
  </S.Wrapper>
);

const NotFoundError = (): ReactElement => (
  <UrlParamError
    heading={t`common.project-not-found`}
    description={t`common.project-not-found-description`}
  />
);

const BadUrlError = (): ReactElement => (
  <UrlParamError
    heading={t`common.invalid-url`}
    description={t`common.invalid-id-description`}
  />
);

const OneMachineView = ({ machineId }: { machineId: Uuid }): ReactNode => {
  const machineResult = useMachine(machineId);
  const { data: machine } = machineResult;
  if (!machine) {
    if (machineResult.isRefetching || machineResult.isLoading) return null;
    return <NotFoundError />;
  }

  // TODO: add more error checking here. If the machine isn't found,
  // then add a permission check here to make sure that this user has
  // access to this machine in this org. If they don't then show a
  // message explaining that they don't have access, and that they
  // should contact their org admin.

  return <MachineDetails machine={machine} />;
};

export const MachinesView = (): ReactNode => {
  const machines = useMachines();
  if (machines.isLoading) return null;
  const showDetachedMachines =
    machines.data &&
    machines.data?.filter((m) => !m.hardwareAttached).length > 1;
  return (
    <>
      <Route path={routes.machines.index}>
        <S.Wrapper>
          <H5>{t`common.machines`}</H5>
          <FullMachineListing />
          {showDetachedMachines && (
            <>
              <H5>{t`common.detached-machines`}</H5>
              <FullDetachedMachineListing />
            </>
          )}
        </S.Wrapper>
      </Route>
      <Route path={routes.machine.wildcards.index}>
        {(p) => {
          const { machineId } = p;
          if (!isValidUuid(machineId)) return <BadUrlError />;

          return <OneMachineView machineId={machineId} />;
        }}
      </Route>
    </>
  );
};
