/**
 * Machines table, used in machines view page and home page.
 *
 * @category components
 * @module machine-listing
 */

import React, { ReactNode } from "react";
import { t } from "@lingui/macro";
import dayjs from "dayjs";

import { useMachines, Machine, useMachinesByOrgs } from "_/data/machines";
import {
  Attempt,
  Project,
  Revision,
  useLastAttemptForEachMachine,
  useProjects,
} from "_/data/projects";
import { Material, useMaterials } from "_/data/materials";
import { Org } from "_/data/orgs";

import {
  Table,
  ColumnDef,
  CustomCellLink,
  CustomCellStatic,
} from "_/components/table";
import { DateOnly, RelativeDateTime } from "_/components/date-time";
import { MenuItem, OverlayMenu } from "_/components/overlay-menu";
import { Button } from "_/components/button";
import { Icon } from "_/components/icon";
import { MaterialsList } from "_/components/materials";
import { AttemptCell } from "_/components/jobs-listing";
import { MachineStatusBadge } from "_/components/machine-header";

import { machineUrls, routeUrls } from "_/routes";

import { indexById } from "_/utils";

import * as S from "./styled";

interface AdminMachineListingProps {
  /** Which org(s) to show machines for. */
  orgs?: Org[];

  /** Adds custom actions to the actions menu. */
  actions?: (machine: Machine) => MenuItem[];

  /** Whether to show the org column. */
  showOrg: boolean;

  /**
   * Whether to include only detached machines. If not truthy, then only show
   * attached machines.
   * */
  detached?: boolean;
}

const ActionsMenu = ({
  machine,
  actions,
}: {
  machine: Machine;
  actions?: (machine: Machine) => MenuItem[];
}) => {
  const menuItems = actions
    ? actions(machine)
    : [
        {
          render: t`common.support`,
          // TODO: this menu item should open a modal with a link to the
          // support email and a checkbox to share this machine's data
          // with AON3D support.
          onClick: () => {},
        },
      ];
  return (
    <OverlayMenu
      target={
        <Button kind="secondary" size="small" icon>
          <Icon variant="MoreVert" />
        </Button>
      }
      placement="bottomStart"
      items={menuItems}
    />
  );
};

const machineColumnDefs: Record<string, () => ColumnDef<Machine>> = {
  name: () => ({
    accessorKey: "name",
    header: t`common.machine`,
    meta: { customTd: true },
    cell: ({ row: { original: rowData } }) => (
      <CustomCellLink to={machineUrls.index(rowData.id)}>
        {rowData.name}
      </CustomCellLink>
    ),
  }),
  model: () => ({
    id: "model",
    accessorKey: "hardware.model",
    header: t`common.machine-model`,
    sortingFn: (a, b, _columnId) => {
      return a.original.hardware.generation - b.original.hardware.generation;
    },
  }),
  serial: () => ({
    accessorKey: "hardware.serial",
    header: t`common.machine-serial`,
    cell: ({ row: { original: rowData } }) =>
      rowData.hardwareAttached ? (
        <div>{rowData.hardware.serial}</div>
      ) : (
        <S.DetachedSerialNumberContainer>
          <S.DetachedSerialNumber>
            {rowData.hardware.serial}{" "}
          </S.DetachedSerialNumber>
          <S.DetachedSerialNumberLabel>
            ({t`common.detached`})
          </S.DetachedSerialNumberLabel>
        </S.DetachedSerialNumberContainer>
      ),
  }),
  manufactureDate: () => ({
    accessorKey: "hardware.manufactureDate",
    header: t`common.machine-manufacture-date`,
    cell: ({ row: { original: rowData } }) => (
      <DateOnly value={rowData.hardware.manufactureDate} />
    ),
  }),
};

export type MachineLastSeenKind = "now" | "stale" | "offline" | "never";

export const calculateMachineLastSeenKind = ({
  lastSeen,
  updatedAt,
}: {
  /** When the server last heard from the machine */
  lastSeen: number | undefined | null;
  /** When we've gotten the latest info from the server */
  updatedAt: number;
}): MachineLastSeenKind => {
  const lastSeenDayjs = lastSeen ? dayjs(lastSeen) : null;
  const staleTime = dayjs(updatedAt).subtract(1, "minutes");
  const offlineTime = dayjs(updatedAt).subtract(15, "minutes");

  if (lastSeenDayjs === null || lastSeenDayjs === undefined) {
    return "never";
  } else if (lastSeenDayjs?.isBefore(offlineTime)) {
    return "offline";
  } else if (lastSeenDayjs?.isBefore(staleTime)) {
    return "stale";
  } else {
    return "now"; // seen within 1 minute
  }
};

/**
 * Show the relative time that this machine last contacted the server, and a
 * small icon indicating how long it's been.
 * */
export const MachineLastSeen = ({
  lastSeen,
  updatedAt,
}: {
  /** When the server last heard from the machine */
  lastSeen: number | undefined | null;
  /** When we've gotten the latest info from the server */
  updatedAt: number;
}) => {
  const lastSeenKind = calculateMachineLastSeenKind({ lastSeen, updatedAt });

  const lastSeenColors = {
    now: "green",
    offline: "red",
    stale: "orange",
    never: "grey",
  } as const;

  return (
    <S.LastSeenCell>
      <S.LastSeenDot variant="Circle" $color={lastSeenColors[lastSeenKind]} />
      <RelativeDateTime
        value={lastSeen}
        isNow={lastSeenKind === "now"}
        defaultValue={t`components.machine-listing.never`}
      />
    </S.LastSeenCell>
  );
};

const orgLinkColumnDef = (): ColumnDef<Machine> => ({
  accessorKey: "org",
  header: t`common.org`,
  meta: { customTd: true },
  cell: ({ row: { original: rowData } }) => (
    <CustomCellLink
      to={routeUrls.admin.organization((rowData as MachineWithOrg).org.id)}
    >
      {(rowData as MachineWithOrg).org.name}
    </CustomCellLink>
  ),
});

/**
 * Function to generate the `lastSeen` columnDef, based on when the machine records were last updated
 */
const lastSeenColumnDef = (updatedAt: number): ColumnDef<Machine> => {
  return {
    accessorKey: "lastSeen",
    header: t`common.machine-last-seen`,
    sortingFn: (a, b, _columnId) => {
      const aLastSeen = new Date(a.original.lastSeen ?? 0).getTime();
      const bLastSeen = new Date(b.original.lastSeen ?? 0).getTime();

      return aLastSeen - bLastSeen;
    },
    cell: ({ row: { original: rowData } }) => {
      const lastSeen = rowData.lastSeen
        ? new Date(rowData.lastSeen).getTime()
        : undefined;
      return <MachineLastSeen lastSeen={lastSeen} updatedAt={updatedAt} />;
    },
  };
};

const lastAttemptMaterialsColumnDef = (): ColumnDef<MachineWithLastAttempt> => {
  return {
    header: t`common.last-attempt-materials`,
    cell: ({ row: { original: rowData } }) => {
      return rowData.lastAttempt ? (
        <MaterialsList materials={rowData.lastAttemptMaterials} />
      ) : null;
    },
  };
};

const lastAttemptColumnDef = (): ColumnDef<MachineWithLastAttempt> => ({
  header: t`common.last-print-job`,
  meta: { customTd: true },
  cell: ({ row: { original: rowData } }) => {
    if (
      !rowData.lastAttempt ||
      !rowData.lastAttemptProject ||
      !rowData.lastAttemptRevision
    ) {
      return <CustomCellStatic />;
    }
    return (
      <AttemptCell
        project={rowData.lastAttemptProject}
        revision={rowData.lastAttemptRevision}
        attempt={rowData.lastAttempt}
      />
    );
  },
});

const machineStatusColumnDef = (updatedAt: number): ColumnDef<Machine> => ({
  id: "status",
  header: t`common.status`,
  cell: ({ row: { original: rowData } }) => (
    <MachineStatusBadge
      machine={rowData}
      lastSeen={
        rowData.lastSeen
          ? new Date(rowData.lastSeen ?? undefined).getTime()
          : undefined
      }
      updatedAt={updatedAt}
    />
  ),
});

/**
 * Function to generate ColumnDef for action items available on a machine record
 */
const actionsColumnDef = (
  actions?: (machine: Machine) => MenuItem[]
): ColumnDef<Machine> => {
  return {
    id: "actions",
    header: t`common.actions`,
    cell: ({ row: { original: rowData } }) => (
      <ActionsMenu machine={rowData} actions={actions} />
    ),
  };
};

const NoMachinesMessage = () => (
  <S.NoMachinesContainer>{t`components.machine-listing.no-machines`}</S.NoMachinesContainer>
);

const defaultMachineListSort = [
  { id: "model", desc: true },
  { id: "name", desc: false },
];

type MachineWithLastAttempt = Machine & {
  lastAttempt: Attempt | undefined;
  lastAttemptRevision: Revision | undefined;
  lastAttemptProject: Project | undefined;
  lastAttemptMaterials: Material[] | undefined;
};

export const FullMachineListing = (): ReactNode => {
  return <NonAdminMachineListing view="full" />;
};

export const FullDetachedMachineListing = (): ReactNode => {
  return <NonAdminMachineListing view="full" detached />;
};

export const PartialMachineListing = (): ReactNode => {
  return <NonAdminMachineListing view="partial" />;
};

const NonAdminMachineListing = ({
  view,
  detached = false,
}: {
  view: "full" | "partial";
  detached?: boolean;
}): ReactNode => {
  const {
    data: unfilteredMachines = [],
    dataUpdatedAt: updatedAt,
    isLoading: machinesLoading,
  } = useMachines();
  const { data: materials = [], isLoading: materialsLoading } = useMaterials();
  const { data: projects = [], isLoading: projectsLoading } = useProjects();
  const { data: attemptsByMachine = {}, isLoading: lastAttemptsLoading } =
    useLastAttemptForEachMachine();

  // Loading state.
  if (
    machinesLoading ||
    materialsLoading ||
    projectsLoading ||
    lastAttemptsLoading
  ) {
    return null;
  }

  // If there's only one machine (typical of customer orgs) then show the
  // machine even if it's not attached to hardware. For orgs with multiple
  // machines (which for the near future will be only AON3D orgs) only show
  // machines that are attached to hardware, unless it's the special "detached
  // machines" table on the machine list page.
  const machines =
    unfilteredMachines.length === 1
      ? unfilteredMachines
      : unfilteredMachines.filter((m) => {
          return detached ? !m.hardwareAttached : m.hardwareAttached;
        });

  if (!machines.length) {
    return <NoMachinesMessage />;
  }

  const revisions = projects.flatMap((p) => p.revisions) ?? [];

  const materialByKey = indexById(materials);
  const projectsByKey = indexById(projects);
  const revisionsByKey = indexById(revisions);

  const machinesWithLastAttempt: MachineWithLastAttempt[] = machines.map(
    (machine) => {
      const lastAttempt = attemptsByMachine[machine.id];
      const lastAttemptRevision =
        lastAttempt && revisionsByKey[lastAttempt.revisionId];
      const lastAttemptProject =
        lastAttemptRevision && projectsByKey[lastAttemptRevision.projectId];
      const lastAttemptMaterials = lastAttemptRevision?.materials
        .map((id, i) => {
          const material = id ? materialByKey[id] : undefined;
          if (!material) {
            if (i === 0 && !id) {
              throw new Error(
                `Missing primary material in revision ${lastAttemptRevision.id}`
              );
            }
          }
          return material;
        })
        .filter(Boolean);

      const result = {
        ...machine,
        lastAttempt,
        lastAttemptRevision,
        lastAttemptProject,
        lastAttemptMaterials,
      };
      return result;
    }
  );

  // The casts below are OK because MachineWithLastAttempt contains all the
  // fields of Machine.
  const columns: ColumnDef<MachineWithLastAttempt>[] =
    view === "full"
      ? [
          machineColumnDefs.name() as ColumnDef<MachineWithLastAttempt>,
          machineColumnDefs.model() as ColumnDef<MachineWithLastAttempt>,
          machineStatusColumnDef(
            updatedAt
          ) as ColumnDef<MachineWithLastAttempt>,
          lastSeenColumnDef(updatedAt) as ColumnDef<MachineWithLastAttempt>,
          lastAttemptColumnDef(),
          lastAttemptMaterialsColumnDef(),
          actionsColumnDef() as ColumnDef<MachineWithLastAttempt>,
        ]
      : [
          machineColumnDefs.name() as ColumnDef<MachineWithLastAttempt>,
          machineColumnDefs.model() as ColumnDef<MachineWithLastAttempt>,
          machineStatusColumnDef(
            updatedAt
          ) as ColumnDef<MachineWithLastAttempt>,
        ];

  return (
    <Table
      columns={columns}
      data={machinesWithLastAttempt}
      initialState={{
        sorting: defaultMachineListSort,
        columnVisibility: { model: false },
      }}
    />
  );
};

export interface MachineWithOrg extends Machine {
  org: Org;
}

export const AdminMachineListing = ({
  orgs,
  actions,
  showOrg,
  detached,
}: AdminMachineListingProps): ReactNode => {
  const results = useMachinesByOrgs(orgs);
  if (results.isLoading) return null;
  if (!results.data?.length) return <NoMachinesMessage />;

  const machinesAndOrgs: MachineWithOrg[] = results.data
    .filter((machineAndOrg) => {
      return detached
        ? !machineAndOrg.machine.hardwareAttached
        : machineAndOrg.machine.hardwareAttached;
    })
    .map((machineAndOrg) => ({
      ...machineAndOrg.machine,
      org: machineAndOrg.org,
    }));

  const { serial, manufactureDate, name, model } = machineColumnDefs;

  // The casts below are OK because MachineWithOrg contains all the
  // fields of Machine.
  const columns = [
    showOrg ? orgLinkColumnDef() : undefined,
    name(),
    model(),
    serial(),
    manufactureDate(),
    lastSeenColumnDef(results.dataUpdatedAt),
    actionsColumnDef(actions),
  ].filter(Boolean);

  return (
    <Table
      columns={columns}
      data={machinesAndOrgs}
      initialState={{
        sorting: defaultMachineListSort,
      }}
    />
  );
};
